import gql from "graphql-tag";

import authorizationCorrespondenceInfo from "./authorizationCorrespondenceInfo";
import noteInfo from "./noteInfo";
import portalInformation from "./portalInformation";

export default gql`
  fragment authorizationInfo on Authorization {
    id
    config
    status
    formId
    formDetails
    updatedAt
    submittedAt
    caremetxEnrollmentDisplayStatus
    supportsEnrollment
    enrollmentProvider
    createdById
    createdByUser
    createdAt
    version
    InstitutionId
    InsuranceCompanyId
    institutionAuthorizationId
    isArchived
    lastCheckedAt
    displayReviewWarning
    hideFromExpiring
    PortalId
    portalKey
    portalHub
    isWebExtension
    ICDs
    HCPCSCodes
    isReferral
    portalTitle
    portalAuthorizationId
    requiresAssociationReview
    emailReminderAt
    emailReminderDescription
    integratedEMRDocumentsDownloaded
    dateOfCurrentStatus
    type
    submissionPhoneNumber
    trackingStatus
    DrugOptionId
    PortalId
    paOrigin

    tags {
      value
    }

    customPortalSteps {
      key
      id
      display
      value
      isTerminal
      message
      type
      subType
      title
      requiresHardReset
      imageURL
      isDraft
      errorGroup
    }

    patient {
      id
      firstName
      lastName
      InsuranceCompanyId
      institutionPatientId
      dob
    }

    insuranceCompany {
      id
      name
      responseRangeMax
      responseRangeMin
      isArchived
    }

    latestCorrespondence {
      ...authorizationCorrespondenceInfo
    }

    correspondences {
      ...authorizationCorrespondenceInfo
    }

    latestNote {
      ...noteInfo
    }

    portal {
      ...portalInformation
    }

    formFieldConfig {
      requiredFields
      hiddenFields
      additionalFields
      maxIcdCount
      maxHcpcsCount
      hcpcsUnits {
        value
        label
      }
      fieldOptionsList {
        fieldKey
        maxLength
      }
    }

    followUp {
      id
      createdAt
      isReviewed
      type
    }

    steps {
      key
      title
      number
      active
      section
      belongsTo
    }

    site {
      id
      host
    }

    drugConfiguration {
      useLetterOfMedicalNecessity
      lomnFields {
        key
        title
        type
        optional
      }
      appealFields {
        key
        title
        type
        optional
      }
      lomnMarkdownTemplate
      appealMarkdownTemplate
      appealFillStepHtml
      appealLetterHtml
      patientAccessInfoHtml
      isiHtml
      reviewAndSubmitNote
      fieldPrimaryDrugName_note
      fieldInsuranceOrganization_note
    }
  }

  ${authorizationCorrespondenceInfo}
  ${noteInfo}
  ${portalInformation}
`;
