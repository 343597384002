import React from "react";
import { useMutation } from "@apollo/client";
import styled from "styled-components";

import { MdFavorite as FavoriteIcon } from "@react-icons/all-files/md/MdFavorite";
import { MdFavoriteBorder as EmptyFavoriteIcon } from "@react-icons/all-files/md/MdFavoriteBorder";

import {
  SET_FAVORITE_FORM,
  DELETE_FAVORITE_FORM,
} from "../graphql/FavoriteForm";

const FavoriteIconStyled = styled(({ isFavorite, ...rest }) => (
  <FavoriteIcon {...rest} />
))`
  display: ${({ isFavorite }) => (isFavorite ? "inline" : "none")};
  color: ${(props) => props.theme.purple};
  margin: 0;
  height: 24px;
  width: 24px;
  cursor: pointer;
`;
const EmptyFavoriteIconStyled = styled(({ isFavorite, ...rest }) => (
  <EmptyFavoriteIcon {...rest} />
))`
  display: ${({ isFavorite }) => (isFavorite ? "none" : "inline")};
  color: ${(props) => props.theme.purple};
  margin: 0;
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

const IconWrapper = styled(({ isFavorite, ...rest }) => <span {...rest} />)`
  &:hover {
    ${FavoriteIconStyled} {
      display: ${({ isFavorite }) => (isFavorite ? "none" : "inline")};
    }
    ${EmptyFavoriteIconStyled} {
      display: ${({ isFavorite }) => (isFavorite ? "inline" : "none")};
    }
  }
`;

interface FavoriteFormProps {
  isFavorite: boolean;
  formId: number;
  onChange?: (id: string) => void;
}

const FavoriteForm: React.FC<FavoriteFormProps> = ({
  isFavorite,
  formId,
  onChange,
}) => {
  const [setFavoriteForm] = useMutation(SET_FAVORITE_FORM);
  const [deleteFavoriteForm] = useMutation(DELETE_FAVORITE_FORM);

  const handleClick = async () => {
    const variables = {
      authorizationFormId: formId,
    };

    if (onChange) onChange(formId.toString());

    if (isFavorite) {
      await deleteFavoriteForm({ variables });
    } else {
      await setFavoriteForm({ variables });
    }
  };

  return (
    <IconWrapper
      isFavorite={isFavorite}
      data-cy={
        isFavorite ? "actionDeleteFavoriteForm" : "actionSetFavoriteForm"
      }
      onClick={handleClick}
    >
      <FavoriteIconStyled isFavorite={isFavorite} />
      <EmptyFavoriteIconStyled isFavorite={isFavorite} />
    </IconWrapper>
  );
};

export default FavoriteForm;
