import React, { useContext, useState } from "react";
import { useHistory } from "../hooks/routing";
import styled from "styled-components";
import { useAlert } from "react-alert";
import _ from "lodash";
import colors from "Resources/colors";

import {
  Mutation,
  MutationCreateAuthorizationFromBvArgs,
  MutationCreateAuthorizationFromEnrollmentArgs,
  PaOriginType,
} from "@samacare/graphql";
import PortalSelectionModal from "./PortalSelectionModal";
import ROUTE_PATHS from "../routes/ROUTE_PATHS";
import { useCurrentAccount } from "../graphql/Account";
import {
  AUTHORIZATION_PAGINATION_QUERY_NAME,
  useCreateAuthorization,
  withCreateAuthorizationFromBvMutation,
  ALL_AUTHORIZATIONS_QUERY_NAME,
  withCreateAuthorizationFromEnrollmentMutation,
} from "../graphql/Authorization";
import { useMutation } from "@apollo/client";
import { useConfig, useSamaCareBrandName } from "@@hooks/config";
import { Paper, Tooltip } from "@samacare/design/core";
import { useFeatureFlag } from "../hooks";
import { WebExtensionContext } from "../contexts/webExtension";
import { PopperWithTarget } from "./PopperWithTarget";
import { doCreate } from "../util/authUtils";
import { PortalContext } from "../../app/contexts/portalContext";

export type NewPriorAuthButtonProps = React.ComponentProps<
  typeof NewPriorAuthButton
>;

const MenuItem = styled.div`
  padding: 10px;
  text-align: center;
  width: 100px;
  :hover {
    cursor: pointer;
    background-color: ${colors.lightGray};
  }
`;

const NewPriorAuthButton: React.VoidFunctionComponent<{
  children: React.ReactNode;
  configOverrides?: Record<string, unknown>;
  patientId?: string;
}> = ({ children, configOverrides, patientId }) => {
  const config = useConfig();
  const [portalSelectionOpen, setPortalSelectionOpen] = useState(false);
  const [createAuthorization] = useCreateAuthorization();
  const [account] = useCurrentAccount();
  const history = useHistory();
  const alert = useAlert();
  const brandName = useSamaCareBrandName();
  const { isWebExtensionConnected } = useContext(WebExtensionContext);
  const { setPortalData } = useContext(PortalContext);

  const enableBlendedPortalWorkflow = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.EnableBlendedPortalWorkflow
  );
  const isEdiAuthEnabled = useFeatureFlag<boolean>(
    config.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS.AvailityEdiSubmission
  );

  const [createAuthorizationFromBv] = useMutation<
    Mutation,
    MutationCreateAuthorizationFromBvArgs
  >(withCreateAuthorizationFromBvMutation, {
    refetchQueries: [
      ALL_AUTHORIZATIONS_QUERY_NAME,
      AUTHORIZATION_PAGINATION_QUERY_NAME,
    ],
  });

  const [createAuthorizationFromExistingEnrollment] = useMutation<
    Mutation,
    MutationCreateAuthorizationFromEnrollmentArgs
  >(withCreateAuthorizationFromEnrollmentMutation, {
    refetchQueries: [
      ALL_AUTHORIZATIONS_QUERY_NAME,
      AUTHORIZATION_PAGINATION_QUERY_NAME,
    ],
  });

  const handleCreate = async ({
    type,
    portal,
    onClickConfigOverride = {},
  }: {
    type: string;
    portal?: { id?: string; key?: string; isLegacy: boolean };
    onClickConfigOverride?: Record<string, unknown>;
  }) => {
    if (portal && !portal.isLegacy && enableBlendedPortalWorkflow) {
      setPortalData({ portal, onClickConfigOverride });
      history.push(ROUTE_PATHS.PORTAL_AUTH_NEW.path);
    } else {
      await doCreate({
        type,
        paOrigin: PaOriginType.WebApp,
        portal,
        onClickConfigOverride,
        account,
        configOverrides,
        config,
        patientId,
        createAuthorizationFromBv,
        createAuthorizationFromExistingEnrollment,
        createAuthorization,
        history,
        alert,
        isBlendedPortalWorkflow: enableBlendedPortalWorkflow,
      });
    }
  };

  const handleEdiAuthCreate = () => {
    history.push(ROUTE_PATHS.EDI_AUTH_CREATE.path);
  };

  if (account == null) return null;

  const authorizationTypes = _.compact([
    {
      key: "newFormAuth",
      text: "Form",
      type: config.CONSTANTS.AUTHORIZATION_TYPES.FORM.key,
      description:
        "An insurance authorization form is electronically filled and faxed to the payer",
    },
    !_.isEmpty(account?.institution?.ReferralForms) && {
      key: "newReferralAuth",
      text: "Referral",
      type: config.CONSTANTS.AUTHORIZATION_TYPES.REFERRAL.key,
      description: undefined,
    },
    {
      key: "newWebAuth",
      text: "Web Portal",
      type: config.CONSTANTS.AUTHORIZATION_TYPES.PORTAL.key,
      description: "The payer uses an online portal to submit authorizations",
      onClick: () => setPortalSelectionOpen(true),
    },
    {
      key: "newExternalAuth",
      text: "External",
      type: config.CONSTANTS.AUTHORIZATION_TYPES.EXTERNAL.key,
      description: `Track an authorization you submitted outside of ${brandName}`,
    },
    account?.institution?.featureFlags?.MultiPartyAuthorization && {
      key: "newMultiAuth",
      text: "Multi Authorization",
      type: config.CONSTANTS.AUTHORIZATION_TYPES.MULTI_PARTY.key,
      description:
        "The authorization must be faxed to a third-party for completion before it goes to the payer",
    },
    isEdiAuthEnabled && {
      key: "newEdiAuth",
      text: "ePA",
      type: config.CONSTANTS.AUTHORIZATION_TYPES.EDI.key,
      description: "Submit authorizations directly to the payer electronically",
      onClick: handleEdiAuthCreate,
    },
  ]) as {
    key: string;
    text: string;
    type: string;
    description?: string;
    onClick?: () => void;
  }[];

  return (
    <div data-cy="controlNewAuthorization">
      {account?.institution?.featureFlags?.Novartis ? (
        <div
          onClick={async () =>
            handleCreate({
              type: config.CONSTANTS.AUTHORIZATION_TYPES.MULTI_PARTY.key,
              onClickConfigOverride: {
                // Hack which allows us to support Novartis authorizations as pharmacy benefit authorizations
                [config.DEFAULT_FIELDS.REQUEST_TYPE.key]: "Pharmacy Request",

                // We should not be setting requested by details for multiparty authorizations
                [config.DEFAULT_FIELDS.REQUESTED_BY_FIRST_NAME.key]: null,
                [config.DEFAULT_FIELDS.REQUESTED_BY_LAST_NAME.key]: null,
                [config.DEFAULT_FIELDS.REQUESTED_BY.key]: null,
                [config.DEFAULT_FIELDS.REQUESTED_BY_PHONE.key]: null,
                [config.DEFAULT_FIELDS.REQUESTED_BY_EXTENSION.key]: null,
                [config.DEFAULT_FIELDS.EMAIL_NOTIFICATION_TYPE.key]: null,
                [config.DEFAULT_FIELDS.DEFAULT_CHECKMARK_TRUE.key]: null,
              },
            })
          }
        >
          {children}
        </div>
      ) : (
        <PopperWithTarget target={children} placement="bottom-start">
          <Paper>
            {authorizationTypes.map(
              ({ key, text, type, description, onClick }) => (
                <Tooltip placement="left" key={key} title={description}>
                  <MenuItem
                    data-cy={_.camelCase(`action_${key}`)}
                    onClick={onClick || (async () => handleCreate({ type }))}
                  >
                    {text}
                  </MenuItem>
                </Tooltip>
              )
            )}
          </Paper>
        </PopperWithTarget>
      )}
      {portalSelectionOpen && (
        <PortalSelectionModal
          open={portalSelectionOpen}
          institution={account.institution}
          isWebExtensionConnected={isWebExtensionConnected}
          enableBlendedPortalWorkflow={enableBlendedPortalWorkflow}
          closeModal={() => {
            setPortalSelectionOpen(false);
          }}
          onSelect={async (portal: {
            key?: string;
            insuranceCompanyName: string;
            isLegacy: boolean;
          }) =>
            handleCreate({
              type: config.CONSTANTS.AUTHORIZATION_TYPES.PORTAL.key,
              portal,
            })
          }
        />
      )}
    </div>
  );
};

/**
 * @deprecated Reorganize me?
 */
export default NewPriorAuthButton;
