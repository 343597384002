import { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import _ from "lodash";
import { withAlert } from "react-alert";
import { withApollo } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import { Flex } from "@@ui-kit";
import ContextPane from "AuthorizationSharedComponents/ContextPane";
import { ContextHeader } from "AuthorizationSharedComponents/ContextHeader";
import RequiredFieldModal from "AuthorizationSharedComponents/RequiredFieldModal";
import Prescriber from "AuthorizationSharedSteps/PrescriberSection";
import RequestDetails from "AuthorizationSharedSteps/RequestDetails";
import Signature from "AuthorizationSharedSteps/SignatureSection";
import ResponseSection from "AuthorizationSharedSteps/ResponseSection";

import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import PDFEditor from "../../components/PDFEditor";
import ROUTE_PATHS from "../ROUTE_PATHS";
import {
  resetForm,
  setCurrentStep,
  setFormFields,
  syncAuthorizationDetails,
  toggleHighlightRequiredFields,
} from "../../reducers/formReducer";
import {
  Container,
  FormContainer,
  withAuthorization,
} from "../NewAuthorization/index";
import {
  withUpdateAuthorizationProgress,
  withSubmitAuthorization,
  withUpdateFormDetails,
} from "../../graphql/Authorization";
import { withCurrentAccount } from "../../graphql/Account";
import NewAuthorizationHoc from "../../components/NewAuthorizationHoc";
import InitializeSection from "./ReferralInitializeSection";

const { ERROR_TYPES } = CONFIG.CONSTANTS;

const STEPS = CONFIG.CONSTANTS.REFERRAL_STEPS.ALL;
export class ReferralAuth extends Component {
  state = { requiredFieldModalOpen: false, loading: false };

  getSignatureId = null;

  componentDidMount() {
    const { syncStepToUrl } = this.props;
    syncStepToUrl();
    this.syncLocalState();
  }

  syncLocalState() {
    const { authorization, syncLocalState } = this.props;

    if (authorization) {
      syncLocalState(authorization);
    }
  }

  componentDidUpdate(prevProps) {
    const { onComponentUpdate, authorization, results } = this.props;

    onComponentUpdate(
      prevProps,
      authorization,
      results,
      this.syncLocalState.bind(this)
    );
  }

  saveChanges = async () => {
    const { saveAuthorizationChanges, authorization, alert } = this.props;

    let signatureId;

    if (this.getSignatureId) {
      const signature = await this.getSignatureId();
      signatureId = signature.signatureId;
    }

    if (authorization.patient) {
      await saveAuthorizationChanges(
        signatureId ? { details: { signatureId } } : {}
      );
    } else {
      alert.info("Patient must be selected to save changes");
    }
  };

  updateAndStep = async (isForward) => {
    const { disabled, hasInvalidResults, takeStep } = this.props;

    if (disabled || !isForward) {
      await takeStep(isForward);
    } else if (!hasInvalidResults()) {
      await this.saveChanges();
      await takeStep(isForward);
    } else {
      this.setState({ requiredFieldModalOpen: true });
    }
  };

  saveAndSubmitAuthorization = () => {
    const { submitAuthorization, authorization, alert } = this.props;
    return this.saveChanges()
      .then(() =>
        submitAuthorization({
          variables: { id: authorization.id, send: false },
        })
      )
      .catch((error) => {
        if (_.includes(error.message, ERROR_TYPES.PAGE_LIMIT_EXCEEDED)) {
          alert.error("Attachments exceed page limit");
        } else {
          alert.error("There was an error submitting your authorization");
        }
      });
  };

  submitAndStep = async (onStart, onEnd) => {
    const { disabled, hasInvalidResults, takeStep } = this.props;
    if (disabled) {
      await takeStep(true);
    } else if (!hasInvalidResults()) {
      if (onStart) {
        onStart();
      }

      try {
        await this.saveAndSubmitAuthorization();
        if (onEnd) {
          onEnd();
        }
        takeStep(true);
      } catch {
        if (onEnd) {
          onEnd();
        }
      }
    } else {
      this.setState({ requiredFieldModalOpen: true });
    }
  };

  submitAndSend = async (signatureId) => {
    const {
      submitAuthorization,
      authorization,
      goToLocation,
      alert,
      disabled,
      hasInvalidResults,
    } = this.props;
    if (disabled) {
      alert.error("Authorization already submitted");
    } else if (!hasInvalidResults()) {
      try {
        await submitAuthorization({
          variables: { id: authorization.id, send: true, signatureId },
        });
        goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST.path);
      } catch (e) {
        this.setState({ loading: false });
        alert.error(`There was an error submitting your authorization, ${e}`);
      }
    } else {
      this.setState({ requiredFieldModalOpen: true });
    }
  };

  triggerRequiredModalOnInvalid = (manuallyTrigger) => {
    const { hasInvalidResults } = this.props;

    if (hasInvalidResults(manuallyTrigger)) {
      this.setState({ requiredFieldModalOpen: true });
      return true;
    }

    return false;
  };

  render() {
    const {
      authorization,
      step,
      setStepAndUpdateURL,
      goToLocation,
      disabled,
      setToForm,
      results,
      hasInvalidResults,
      account,
    } = this.props;
    const { requiredFieldModalOpen, loading } = this.state;

    let content;

    if (authorization) {
      switch (step) {
        case STEPS.INITIATE.step:
          content = (
            <InitializeSection
              authorization={authorization}
              isInvalid={(forceResultsInvalid) => {
                if (hasInvalidResults(forceResultsInvalid)) {
                  this.setState({ requiredFieldModalOpen: true });
                  return true;
                }
                return false;
              }}
              nextStep={() => {
                this.updateAndStep(true);
              }}
              disabled={disabled}
              referralForms={account?.institution?.ReferralForms}
            />
          );
          break;
        case STEPS.REQUEST_DETAILS.step:
          content = (
            <RequestDetails
              authorization={authorization}
              triggerRequiredModalOnInvalid={() => {
                this.triggerRequiredModalOnInvalid(true);
              }}
              back={() => {
                this.updateAndStep(false);
              }}
              nextStep={() => {
                this.updateAndStep(true);
              }}
            />
          );
          break;
        case STEPS.PRESCRIBER_AND_LOCATION_DETAILS.step:
          content = (
            <Prescriber
              authorization={authorization}
              account={account}
              back={() => {
                this.updateAndStep(false);
              }}
              nextStep={() => {
                this.updateAndStep(true);
              }}
              saveChanges={this.saveChanges}
            />
          );
          break;
        case STEPS.REFERRAL_DETAILS.step:
          content = (
            <div style={{ marginTop: "16x" }}>
              <PDFEditor
                onBack={() => {
                  this.updateAndStep(false);
                }}
                onSubmit={this.submitAndStep}
                authorization={authorization}
                results={results}
              />
            </div>
          );
          break;
        case STEPS.REVIEW_SIGN_SUBMIT.step:
          content = (
            <div style={{ marginTop: "16x" }}>
              <Signature
                back={() => {
                  this.updateAndStep(false);
                }}
                disabled={disabled}
                nextStep={({ signatureId }) => {
                  if (!disabled) {
                    this.setState({ loading: true });
                  }
                  this.submitAndSend(signatureId);
                }}
                pdfPath={authorization.submittedFormURL}
                set={setToForm}
                authorization={authorization}
                getSignatureId={(getSignatureIdFunc) => {
                  this.getSignatureId = getSignatureIdFunc;
                }}
              />
            </div>
          );
          break;
        case STEPS.SUMMARY.step:
          content = (
            <div style={{ marginTop: "16x" }}>
              <ResponseSection
                goToLocation={() => {
                  this.props.goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST.path);
                }}
                back={() => {
                  this.updateAndStep(false);
                }}
                authorization={authorization}
              />
            </div>
          );
          break;
        default:
          content = <div />;
      }

      return (
        <Flex flexDirection="column" padding="25px 50px 0 50px">
          <ContextHeader
            account={account}
            authorization={authorization}
            backToAuthorizations={() => {
              goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST.path);
            }}
            saveChanges={async () => {
              await this.saveChanges();
            }}
          />
          <Container>
            <FormContainer>{content}</FormContainer>
            <ContextPane
              authorizationId={authorization.id}
              account={account}
              backToAuthorizations={() => {
                goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST.path);
              }}
              setStep={(stepTo) => {
                if (stepTo === STEPS.SUMMARY.step || disabled) {
                  setStepAndUpdateURL(stepTo);
                } else if (stepTo === STEPS.REVIEW_SIGN_SUBMIT.step) {
                  this.setState({ loading: true });
                  this.saveAndSubmitAuthorization()
                    .then(() => {
                      this.setState({ loading: false });
                      setStepAndUpdateURL(stepTo);
                    })
                    .catch(() => {
                      this.setState({ loading: false });
                    });
                } else {
                  this.saveChanges().then(() => {
                    setStepAndUpdateURL(stepTo);
                  });
                }
              }}
            />
            <LoadingSpinner open={loading} />
            <RequiredFieldModal
              closeModal={() => {
                this.setState({ requiredFieldModalOpen: false });
              }}
              open={requiredFieldModalOpen}
            />
          </Container>
        </Flex>
      );
    }
    return <div />;
  }
}

function mapStateToProps(state) {
  return {
    results: state.form.results,
    attachments: state.form.attachments,
    step: state.form.currentStep,
    disabled: state.form.disabled,
    requiredFieldKeys: state.form.requiredFieldKeys,
    DrugOptionId: state.form.DrugOptionId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    syncLocalState: ({
      config,
      status,
      attachments,
      portalTitle,
      DrugOptionId,
    }) => {
      dispatch(
        syncAuthorizationDetails({
          config,
          status,
          attachments,
          portalTitle,
          DrugOptionId,
        })
      );
    },
    goToLocation: (location) => {
      dispatch(push(location));
    },
    setToForm: (toSet) => dispatch(setFormFields(toSet)),
    setStep(step) {
      dispatch(setCurrentStep(step));
    },
    reset() {
      dispatch(resetForm());
    },
    toggleHighlightRequiredFields(shouldBeHighlighted) {
      dispatch(toggleHighlightRequiredFields(shouldBeHighlighted));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withUpdateFormDetails,
    withSubmitAuthorization,
    withUpdateAuthorizationProgress,
    withAuthorization,
    withCurrentAccount
  )(withApollo(withAlert()(NewAuthorizationHoc(ReferralAuth))))
);
