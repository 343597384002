import _ from "lodash";
import React, { useMemo } from "react";
import { useQuery } from "@apollo/client";
import {
  Typography,
  Button,
  Box,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  Autocomplete,
  Paper,
  Popper,
  PaperProps,
  PopperProps,
} from "@samacare/design/core";
import { useTheme } from "styled-components";
import SearchIcon from "@samacare/design/core/icons/Search";
import CancelIcon from "@samacare/design/core/icons/Cancel";
import ArrowDropDownIcon from "@samacare/design/core/icons/ArrowDropDown";

import { DialogModal } from "@@components/DialogModal";
import GetPortals from "../../../graphql/GetPortals.gql";
import { GetPortalsQuery } from "@@generated/graphql";
import { Portal } from "@samacare/graphql";
import renderOptionsAutoSuggest from "@@components/RenderOptionsAutoSuggest";

const CustomPaper = (props: PaperProps) => (
  <Paper
    {...props}
    sx={{
      boxShadow: "none",
      border: "none",
      overflow: "hidden",
    }}
  />
);

const CustomPopper = (props: PopperProps) => (
  <Popper
    {...props}
    sx={{
      boxShadow: "none",
      border: "none",
    }}
  />
);

export const PortalsDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  handlePortalSelect: (portal: Partial<Portal>) => Promise<void>;
}> = ({ open, onClose, handlePortalSelect }) => {
  const theme = useTheme();
  const [searchValue, setSearchValue] = React.useState("");
  const [selectedValue, setSelectedValue] = React.useState(null);

  const { data, loading } = useQuery<GetPortalsQuery>(GetPortals);
  const portalList = useMemo(() => {
    if (!data) return [];

    const topPortals = _.sortBy(
      data.portals.filter((portal) => portal.isTop && !portal.isHidden),
      "title"
    );
    const allPortals = _.sortBy(
      data.portals.filter((portal) => !portal.isTop && !portal.isHidden),
      "title"
    );

    return [
      ...topPortals.map((portal) => ({ ...portal, group: "Top Portals" })),
      ...allPortals.map((portal) => ({ ...portal, group: "All Portals" })),
    ];
  }, [data]);

  const openPortalInNewTab = async (portal?: Partial<Portal>) => {
    if (portal) {
      await handlePortalSelect(portal);
    } else {
      window.open("about:blank", "_blank");
    }
  };

  const content = (
    <Box>
      <InputLabel
        sx={{
          fontSize: "14px",
          fontWeight: "bold",
          pb: 0.5,
          color: "black",
          mt: 1,
        }}
      >
        Choose portal or go directly to a portal in your browser
      </InputLabel>
      <Autocomplete
        id="portals-autocomplete"
        open
        loading={loading}
        options={portalList}
        groupBy={(option) => option.group}
        getOptionLabel={(option) => option.title}
        inputValue={searchValue}
        onInputChange={(_e, value) => setSearchValue(value)}
        value={selectedValue}
        onChange={(_event, value) => {
          if (value) {
            void openPortalInNewTab(value);
            setSelectedValue(null);
            setSearchValue("");
          }
        }}
        renderInput={(params) => (
          <OutlinedInput
            {...params.InputProps}
            ref={params.InputProps.ref}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
            endAdornment={
              <>
                {searchValue && (
                  <CancelIcon
                    sx={{
                      opacity: "0.54",
                      cursor: "pointer",
                    }}
                    onClick={() => setSearchValue("")}
                  />
                )}
                <ArrowDropDownIcon
                  sx={{
                    position: "absolute",
                    right: 10,
                    color: "inherit",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                />
              </>
            }
            placeholder="Search"
            inputProps={{ ...params.inputProps }}
            sx={{
              width: "100%",
            }}
          />
        )}
        renderOption={(props, option, { inputValue }) =>
          renderOptionsAutoSuggest({
            props,
            option: { id: option.id, title: option.title },
            state: { inputValue },
            sx: {
              "& .MuiTypography-root": {
                fontSize: "14px",
              },
            },
          })
        }
        ListboxProps={{
          style: {
            maxHeight: "328px",
            overflowY: "auto",
            border: "none",
            boxShadow: "none",
          },
        }}
        PaperComponent={CustomPaper}
        PopperComponent={CustomPopper}
        renderGroup={(params) => (
          <div key={params.key}>
            <Typography
              sx={{
                fontWeight: "bold",
                color: "black",
                backgroundColor: "transparent",
                lineHeight: "24px",
                padding: "8px 16px",
                fontSize: "14px",
              }}
            >
              {params.group}
            </Typography>
            {params.children}
          </div>
        )}
        sx={{
          width: "100%",
          height: "345px",
        }}
      />
    </Box>
  );

  const actions = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography
        sx={{
          fontSize: "12px",
          color: theme.gray,
          lineHeight: "1.2",
          display: "flex",
          alignItems: "center",
        }}
      >
        CoPilot works in all portals - Open a portal in a{" "}
        <Button
          onClick={async () => openPortalInNewTab()}
          sx={{
            px: 0.5,
            m: 0,
            fontSize: "12px",
            fontWeight: "normal",
            color: theme.purple,
            textDecoration: "underline",
            minWidth: "auto",
            lineHeight: "1.2",
            verticalAlign: "middle",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          new tab
        </Button>{" "}
        and launch CoPilot.
      </Typography>
      <Button
        onClick={onClose}
        sx={{
          color: theme.purple,
          textDecoration: "underline",
          "&:hover": {
            background: "transparent",
          },
        }}
      >
        Close
      </Button>
    </Box>
  );

  return (
    <DialogModal
      open={open}
      onClose={onClose}
      title="Capture and Store PAs in Any Portal with CoPilot"
      content={content}
      actions={actions}
      divider
    />
  );
};
