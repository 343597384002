import { PureComponent } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import _ from "lodash";
import { graphql } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import { withAlert } from "react-alert";
import { Flex } from "@@ui-kit";
import RequiredFieldModal from "AuthorizationSharedComponents/RequiredFieldModal";
import ContextPane from "AuthorizationSharedComponents/ContextPane";
import { ContextHeader } from "AuthorizationSharedComponents/ContextHeader";
import ResponseSection from "AuthorizationSharedSteps/ResponseSection";
import Signature from "AuthorizationSharedSteps/SignatureSection";
import strings from "Resources/strings";
import * as stepUtils from "../../util/stepUtils";

import { isNovartisAuth } from "../../util/isNovartisAuth";
import Prescriber from "./Prescriber";
import ChangeFormModal from "../NewAuthorization/ChangeFormModal";
import SelectFormModal from "../NewAuthorization/SelectFormModal";
import SelectPhoneList from "../NewAuthorization/SelectFormModal/SelectPhoneList";
import Modal from "../../components/Modal";
import AlertModal from "../../components/AlertModal";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import {
  resetForm,
  setCurrentStep,
  setFormFields,
  syncAuthorizationDetails,
  toggleHighlightRequiredFields,
} from "../../reducers/formReducer";
import InitializeSection from "./InitializeSection";
import ROUTE_PATHS from "../ROUTE_PATHS";
import { withFormById } from "../../graphql/AuthorizationForm";
import PDFEditor from "../../components/PDFEditor";
import RequestDetails from "AuthorizationSharedSteps/RequestDetails";
import {
  withUpdateAuthorizationProgress,
  withSubmitAuthorization,
  withUpdateFormDetails,
  withAuthorizationQuery,
  withUpdateAuthorization,
} from "../../graphql/Authorization";
import { withCurrentAccount } from "../../graphql/Account";
import NewAuthorizationHoc from "../../components/NewAuthorizationHoc";
import { LocateNumberTipModal } from "../NewAuthorization/LocateNumberTipModal";
import { PDFEditorWrapperForManuallyUploadedForm } from "../NewAuthorization/PDFEditorWrapperForManuallyUploadedForm";
import { AuthorizationStepKey } from "@samacare/graphql";

import { useFeatureFlag } from "@@hooks/useFeatureFlag";
import FaxNumberModal from "../NewAuthorization/FaxNumberModal";
import * as React from "react";
import FaxForm from "../NewAuthorization/FaxForm";

export const Container = styled.div`
  display: flex;
  min-height: 100%;
  flex-direction: row;
  width: 100%;
`;

export const FormContainer = styled.div`
  display: flex;
  margin: 16px 16px 16px 0px;
  flex-direction: column;
  width: 100%;
  min-width:${({ stepKey }) =>
    stepKey === AuthorizationStepKey.FormDetails ? "" : "800px"};

  @media (min-width: ${({ stepKey }) =>
    stepKey === AuthorizationStepKey.FormDetails ? "1100px" : ""};
}) {
    width: ${({ stepKey }) =>
      stepKey === AuthorizationStepKey.FormDetails ? "900px" : ""};
  }
`;

export const FormTitle = styled.div`
  font-size: 32px;
  font-weight: 700;
`;

export const FormSubTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: ${(props) => props.theme.darkGray};
  margin-bottom: 10px;
`;

const {
  ERROR_TYPES,
  INSURANCE_TYPES,
  AUTHORIZATION_STATUSES,
  AUTHORIZATION_FORM_TYPE,
} = CONFIG.CONSTANTS;
const fields = CONFIG.DEFAULT_FIELDS;

export const preventFaxNumberUpdate = (authorization) =>
  authorization.submittedAt &&
  authorization.status !== AUTHORIZATION_STATUSES.EDIT_AND_RESUBMIT;
export const getInsuranceType = (results) => {
  if (results[CONFIG.DEFAULT_FIELDS.IS_MEDICAID.key]) {
    return INSURANCE_TYPES.IS_MEDICAID;
  }
  if (results[CONFIG.DEFAULT_FIELDS.IS_MEDICARE_ADVANTAGE.key]) {
    return INSURANCE_TYPES.IS_MEDICARE_ADVANTAGE;
  }
  if (results[CONFIG.DEFAULT_FIELDS.IS_COMMERCIAL.key]) {
    return INSURANCE_TYPES.IS_COMMERCIAL;
  }
  return null;
};

// Higher-Order Component to inject the hook's value into the class component
const withFeatureFlag = (WrappedComponent) => {
  return function FeatureFlagWrapper(props) {
    const isSimplifiedUx = useFeatureFlag(
      window.CONFIG.CONSTANTS.LAUNCH_DARKLY_FEATURE_FLAGS
        .SimplifiedFaxWorkflowUx
    );

    return <WrappedComponent {...props} isSimplifiedUx={isSimplifiedUx} />;
  };
};

export class Form extends PureComponent {
  state = {
    loading: false,
    requiredFieldModalOpen: false,
    alertModalOpen: false,
    showSelectPhoneModal: false,
    showSelectFormModal: false,
    showChangeFormModal: false,
    showLocateNumberTipModal: false,
    showFaxNumberModal: false,
  };

  getSignatureId = null;

  componentDidMount() {
    const { syncStepToUrl } = this.props;
    syncStepToUrl();
    this.syncLocalState();
  }

  componentDidUpdate(prevProps) {
    const { onComponentUpdate, authorization, results } = this.props;

    onComponentUpdate(
      prevProps,
      authorization,
      results,
      this.syncLocalState.bind(this)
    );
  }

  syncLocalState() {
    const { authorization, syncLocalState } = this.props;
    if (authorization) {
      syncLocalState(authorization);
    }
  }

  saveChanges = async (patientId = null) => {
    const {
      authorization,
      saveAuthorizationChanges,
      shouldIncludeCoverLetter,
      alert,
    } = this.props;
    const details = { shouldIncludeCoverLetter };

    if (this.getSignatureId) {
      const { signatureId } = await this.getSignatureId();
      details.signatureId = signatureId;
    }
    // Unable to run save changes without a patient associated
    if (authorization.patient || patientId != null) {
      await saveAuthorizationChanges({ details, patientId });
    } else {
      alert.info("Patient must be selected to save changes");
    }
  };

  useNewWorkflow = async () => {
    const { setToForm } = this.props;

    await setToForm({
      [fields.DRUG_CODE_TYPE.key]: null,
      [fields.BENEFIT_TYPES.key]: null,
      [fields.REQUEST_TYPE.key]: null,
      [fields.HCPCS_0.key]: null,
      [fields.ALL_HCPCS.key]: null,
      [fields.PRIMARY_NDC.key]: null,
      [fields.PRIMARY_DRUG_NAME.key]: null,
      [fields.PATIENT_MEMBER_ID.key]: null,
      [fields.INSURANCE_COMPANY.key]: null,
      [fields.INSURANCE_PLAN.key]: null,
      [fields.INSURANCE_STATE.key]: null,
    });
    await this.saveChanges();
  };

  updateAndStep = async (isForward) => {
    const { disabled, hasInvalidResults, takeStep } = this.props;

    if (disabled || !isForward) {
      await takeStep(isForward);
    } else if (!hasInvalidResults()) {
      await this.saveChanges();
      await takeStep(isForward);
    } else {
      this.setState({ requiredFieldModalOpen: true });
    }
  };

  saveAndSubmitAuthorization = () => {
    const { submitAuthorization, authorization, alert } = this.props;
    return this.saveChanges()
      .then(() =>
        submitAuthorization({
          variables: {
            id: authorization.id,
            send: false,
          },
        })
      )
      .catch((error) => {
        if (_.includes(error.message, ERROR_TYPES.PAGE_LIMIT_EXCEEDED)) {
          this.setState({ alertModalOpen: true });
          throw new Error(ERROR_TYPES.PAGE_LIMIT_EXCEEDED);
        } else {
          alert.error("There was an error submitting your authorization");
        }
      });
  };

  submitAndStep = (onStart, onEnd, shouldStart = () => true) => {
    const { disabled, hasInvalidResults, takeStep } = this.props;
    if (disabled) {
      takeStep(true);
    } else if (!hasInvalidResults()) {
      if (!shouldStart()) {
        return;
      }

      if (onStart) {
        onStart();
      }

      this.saveAndSubmitAuthorization()
        .then(() => {
          if (onEnd) {
            onEnd();
          }
          takeStep(true);
        })
        .catch(() => {
          if (onEnd) {
            onEnd();
          }
        });
    } else {
      this.setState({ requiredFieldModalOpen: true });
    }
  };

  submitAndSend = async (signatureId) => {
    const {
      submitAuthorization,
      authorization,
      goToLocation,
      alert,
      disabled,
      hasInvalidResults,
    } = this.props;
    if (disabled) {
      alert.error("Authorization already submitted");
    } else if (!hasInvalidResults()) {
      try {
        await submitAuthorization({
          variables: { id: authorization.id, send: true, signatureId },
        });
        goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST.path);
      } catch (e) {
        this.setState({ loading: false });
        alert.error(`There was an error submitting your authorization, ${e}`);
      }
    } else {
      this.setState({ requiredFieldModalOpen: true });
    }
  };

  triggerRequiredModalOnInvalid = (manuallyTrigger) => {
    const { hasInvalidResults } = this.props;

    if (hasInvalidResults(manuallyTrigger)) {
      this.setState({ requiredFieldModalOpen: true });
      return true;
    }

    return false;
  };

  toggleFormModal = () => {
    const { showSelectFormModal } = this.state;
    this.setState({
      showSelectFormModal: !showSelectFormModal,
    });
  };

  toggleChangeFormModal = () => {
    const { showChangeFormModal } = this.state;
    this.setState({
      showChangeFormModal: !showChangeFormModal,
    });
  };

  toggleFaxNumberModal = () => {
    const { showFaxNumberModal } = this.state;
    this.setState({
      showFaxNumberModal: !showFaxNumberModal,
    });
  };

  setFormNumber = async (formNumberId) => {
    const { authorization, updateAuthorization, alert } = this.props;

    try {
      await updateAuthorization({
        variables: {
          id: authorization.id,
          patch: { FormNumberId: formNumberId },
        },
      });
      this.setState({ showSelectPhoneModal: false });
    } catch {
      alert.error(strings.NEW_AUTHORIZATIONS.ERROR_SETTING_NUMBER);
    }
  };

  getContent = (step) => {
    const {
      authorization,
      results,
      setToForm,
      disabled,
      hasInvalidResults,
      account,
      isSimplifiedUx,
      updateAuthorization,
    } = this.props;

    const { showFaxNumberModal } = this.state;

    const isNovartis = isNovartisAuth(account);

    const stepKey = stepUtils.getStepByNumber(authorization.steps, step).key;
    const stepMap = {
      [AuthorizationStepKey.Initiate]: (
        <InitializeSection
          saveChanges={this.saveChanges}
          authorization={authorization}
          isInvalid={this.triggerRequiredModalOnInvalid}
          toggleFormModal={isSimplifiedUx ? null : this.toggleFormModal}
          nextStep={() => {
            this.updateAndStep(true);
          }}
          isNovartis={isNovartis}
        />
      ),
      [AuthorizationStepKey.RequestDetails]: (
        <RequestDetails
          authorization={authorization}
          triggerRequiredModalOnInvalid={() => {
            this.triggerRequiredModalOnInvalid(true);
          }}
          back={() => {
            this.updateAndStep(false);
          }}
          nextStep={() => {
            this.updateAndStep(true);
          }}
        />
      ),
      [AuthorizationStepKey.PrescriberAndLocationDetails]: (
        <Prescriber
          authorization={authorization}
          back={() => {
            this.updateAndStep(false);
          }}
          nextStep={() => {
            this.updateAndStep(true);
          }}
          onSubmit={(onStart, onEnd, shouldStart, onInvalidFormState) => {
            if (hasInvalidResults()) {
              onInvalidFormState();
              this.setState({ requiredFieldModalOpen: true });
            } else if (isSimplifiedUx) {
              this.updateAndStep(true);
            } else {
              this.submitAndStep(onStart, onEnd, shouldStart);
            }
          }}
          saveChanges={this.saveChanges}
          hideSelector
          isNovartis={isNovartis}
        />
      ),
      [AuthorizationStepKey.PdfTagger]: (
        <div style={{ marginTop: "16px" }}>
          <PDFEditorWrapperForManuallyUploadedForm
            onBack={() => {
              this.updateAndStep(false);
            }}
            onSubmit={(onStart, onEnd, shouldStart, boxes) => {
              this.submitAndStep(onStart, onEnd, shouldStart, boxes);
            }}
            authorization={authorization}
            samaFaxNumber={account.institution.faxResponseNumber}
          />
        </div>
      ),
      [AuthorizationStepKey.FaxForm]: (
        <FaxForm
          isMultiPartyAuth={
            authorization.type ===
            CONFIG.CONSTANTS.AUTHORIZATION_TYPES.MULTI_PARTY.key
          }
          authorization={authorization}
          jCode={results[CONFIG.DEFAULT_FIELDS.HCPCS_0.key]}
          insuranceType={getInsuranceType(results)}
          insuranceName={results[CONFIG.DEFAULT_FIELDS.INSURANCE_COMPANY.key]}
          insuranceCompanyId={authorization.InsuranceCompanyId}
          onSubmit={async ({ formId }) => {
            try {
              await updateAuthorization({
                variables: {
                  id: authorization.id,
                  patch: { formId },
                },
              });
              await this.saveChanges(true);
              this.toggleFaxNumberModal();
            } catch {
              alert.error(
                "There was an error setting the form or submission number"
              );
            }
          }}
          set={setToForm}
          state={results[CONFIG.DEFAULT_FIELDS.INSURANCE_STATE.key]}
          showLocateNumberTip={() => {
            this.setState({
              showLocateNumberTipModal: true,
              showSelectFormModal: false,
            });
          }}
          missingAuthSelected={null}
          NDC={results[CONFIG.DEFAULT_FIELDS.PRIMARY_NDC.key]}
          benefitType={results[CONFIG.DEFAULT_FIELDS.BENEFIT_TYPES.key]}
        />
      ),
      [AuthorizationStepKey.FormDetails]: (
        <div style={{ marginTop: "16px" }}>
          <PDFEditor
            onBack={() => {
              this.updateAndStep(false);
            }}
            onSubmit={({ onStart, onEnd, shouldStart, onInvalidFormState }) => {
              if (hasInvalidResults()) {
                onInvalidFormState();
                this.setState({ requiredFieldModalOpen: true });
              }
              if (isSimplifiedUx && !showFaxNumberModal) {
                this.toggleFaxNumberModal();
              } else {
                this.submitAndStep(onStart, onEnd, shouldStart);
              }
            }}
            authorization={authorization}
            results={results}
            reportErrorUserDetails={`${_.get(account, "firstName")} ${_.get(
              account,
              "lastName"
            )} with email ${_.get(account, "email")}`}
            hideAttachment
          />
        </div>
      ),
      [AuthorizationStepKey.ReviewSignSubmit]: (
        <div style={{ marginTop: "16px" }}>
          <Signature
            back={() => {
              this.updateAndStep(false);
            }}
            disabled={disabled}
            nextStep={({ signatureId }) => {
              if (!disabled) {
                this.setState({ loading: true });
              }
              this.submitAndSend(signatureId);
            }}
            pdfPath={authorization.submittedFormURL}
            set={setToForm}
            authorization={authorization}
            getSignatureId={(getSignatureIdFunc) => {
              this.getSignatureId = getSignatureIdFunc;
            }}
            hideSignatureContainer
            isSimplifiedUX={isSimplifiedUx}
          />
        </div>
      ),
      [AuthorizationStepKey.Summary]: (
        <div style={{ marginTop: "16px" }}>
          <ResponseSection
            goToLocation={this.props.goToLocation}
            back={() => {
              this.updateAndStep(false);
            }}
            authorization={authorization}
            restrictCorrespondences={isNovartis}
          />
        </div>
      ),
    };
    return (
      <FormContainer stepKey={stepKey}>{stepMap[stepKey] ?? ""}</FormContainer>
    );
  };

  render() {
    const {
      authorization,
      goToLocation,
      disabled,
      step,
      setStepAndUpdateURL,
      form,
      results,
      setToForm,
      account,
      alert,
      updateAuthorization,
      isSimplifiedUx,
    } = this.props;
    const {
      requiredFieldModalOpen,
      loading,
      alertModalOpen,
      showSelectPhoneModal,
      showSelectFormModal,
      showChangeFormModal,
      showLocateNumberTipModal,
      showFaxNumberModal,
    } = this.state;
    const insuranceCompanyId =
      _.get(authorization, "InsuranceCompanyId") ||
      _.get(authorization, "patient.InsuranceCompanyId");

    /**
     * We will waite until the authorization and account data
     * ready before we render our front end
     */
    if (authorization && account) {
      const content = this.getContent(step);

      return (
        <Flex flexDirection="column" padding="25px 50px 0 50px">
          <ContextHeader
            account={account}
            authorization={authorization}
            backToAuthorizations={() => {
              goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST.path);
            }}
            form={form}
            preventFaxNumberUpdate={preventFaxNumberUpdate(authorization)}
            handleFaxNumberUpdate={() =>
              this.setState({ showSelectPhoneModal: true })
            }
            saveChanges={this.saveChanges}
            toggleFormModal={this.toggleChangeFormModal}
            hideDownloadButton
          />
          <Container>
            {content}
            <ContextPane
              authorizationId={authorization.id}
              account={account}
              backToAuthorizations={() => {
                goToLocation(ROUTE_PATHS.AUTHORIZATION_LIST.path);
              }}
              setStep={(stepTo) => {
                if (
                  stepTo ===
                    stepUtils.stepNumberForKey(
                      authorization.steps,
                      AuthorizationStepKey.Summary
                    ) ||
                  disabled
                ) {
                  setStepAndUpdateURL(stepTo);
                } else if (
                  stepTo ===
                  stepUtils.stepNumberForKey(
                    authorization.steps,
                    AuthorizationStepKey.ReviewSignSubmit
                  )
                ) {
                  this.setState({ loading: true });
                  this.saveAndSubmitAuthorization()
                    .then(() => {
                      this.setState({ loading: false });
                      setStepAndUpdateURL(stepTo);
                    })
                    .catch(() => {
                      this.setState({ loading: false });
                    });
                } else {
                  this.saveChanges().then(() => {
                    setStepAndUpdateURL(stepTo);
                  });
                }
              }}
            />
            <RequiredFieldModal
              closeModal={() => {
                this.setState({ requiredFieldModalOpen: false });
              }}
              open={requiredFieldModalOpen}
            />
            <AlertModal
              closeModal={() => {
                this.setState({ alertModalOpen: false });
              }}
              open={alertModalOpen}
              content={ERROR_TYPES.PAGE_LIMIT_EXCEEDED}
              header="Submission Error"
            />
            <LoadingSpinner open={loading} />
            <Modal
              header="Edit Fax Number"
              onClick={() => this.setState({ showSelectPhoneModal: false })}
              open={showSelectPhoneModal && !isSimplifiedUx}
            >
              <div style={{ width: 600 }}>
                <SelectPhoneList
                  authorizationFormId={_.get(form, "id")}
                  authorizationId={authorization.id}
                  institutionId={authorization.InstitutionId}
                  insuranceCompanyId={insuranceCompanyId}
                  onSelect={this.setFormNumber}
                  showLocateNumberTip={() => {
                    this.setState({
                      showLocateNumberTipModal: true,
                      showSelectPhoneModal: false,
                    });
                  }}
                  missingAuthSelected={null}
                />
              </div>
            </Modal>
            <FaxNumberModal
              open={showSelectPhoneModal && isSimplifiedUx}
              onClose={() =>
                this.setState({
                  showSelectPhoneModal: false,
                })
              }
              onSubmit={({ formNumberId }) => this.setFormNumber(formNumberId)}
              authorizationForm={form}
              authorizationId={authorization.id}
              insuranceCompanyId={insuranceCompanyId}
              displayForm={false}
            />
            {showChangeFormModal && (
              <ChangeFormModal
                open={showChangeFormModal}
                close={this.toggleChangeFormModal}
                useExistingWorkflow={() => {
                  this.toggleChangeFormModal();
                  if (isSimplifiedUx) {
                    setStepAndUpdateURL(4);
                    return;
                  }
                  this.toggleFormModal();
                }}
                selectNewWorkflow={() => {
                  this.toggleChangeFormModal();
                  setStepAndUpdateURL(1); // If user wants to use a new workflow, always go to step 1
                  this.useNewWorkflow();
                }}
              />
            )}
            {showSelectFormModal && (
              <SelectFormModal
                skipFormSelection={
                  !!form &&
                  form.type === AUTHORIZATION_FORM_TYPE.MANUALLY_TAGGED_FORM
                }
                authorization={authorization}
                close={this.toggleFormModal}
                jCode={results[CONFIG.DEFAULT_FIELDS.HCPCS_0.key]}
                insuranceType={getInsuranceType(results)}
                insuranceCompanyId={insuranceCompanyId}
                insuranceName={
                  results[CONFIG.DEFAULT_FIELDS.INSURANCE_COMPANY.key]
                }
                onSubmit={async ({ formId, formNumberId }) => {
                  try {
                    await updateAuthorization({
                      variables: {
                        id: authorization.id,
                        patch: { formId, FormNumberId: formNumberId },
                      },
                    });
                    this.toggleFormModal();
                    if (step !== 1) {
                      this.saveChanges(true);
                    } else {
                      this.updateAndStep(true);
                    }
                  } catch {
                    alert.error(
                      "There was an error setting the form or submission number"
                    );
                  }
                }}
                open={showSelectFormModal}
                set={setToForm}
                state={results[CONFIG.DEFAULT_FIELDS.INSURANCE_STATE.key]}
                showLocateNumberTip={() => {
                  this.setState({
                    showLocateNumberTipModal: true,
                    showSelectFormModal: false,
                  });
                }}
                missingAuthSelected={null}
                NDC={results[CONFIG.DEFAULT_FIELDS.PRIMARY_NDC.key]}
                benefitType={results[CONFIG.DEFAULT_FIELDS.BENEFIT_TYPES.key]}
                isSimplifiedUx={isSimplifiedUx}
              />
            )}
            {showLocateNumberTipModal && !isSimplifiedUx && (
              <LocateNumberTipModal
                onClick={() => {
                  this.setState({ showLocateNumberTipModal: false });
                }}
              />
            )}
            {showFaxNumberModal && isSimplifiedUx && (
              <FaxNumberModal
                open={showFaxNumberModal}
                onClose={this.toggleFaxNumberModal}
                onSubmit={async ({ formId, formNumberId }) => {
                  try {
                    await updateAuthorization({
                      variables: {
                        id: authorization.id,
                        patch: { formId, FormNumberId: formNumberId },
                      },
                    });
                    this.toggleFaxNumberModal();
                    if (isSimplifiedUx) {
                      this.setState({ loading: true });
                      await this.saveAndSubmitAuthorization();
                      this.setState({ loading: false });
                    }
                    await this.updateAndStep(true);
                  } catch {
                    alert.error(
                      "There was an error setting the form or submission number"
                    );
                  }
                }}
                authorizationForm={form}
                authorizationId={authorization.id}
                insuranceCompanyId={insuranceCompanyId}
                displayForm={true}
              />
            )}
          </Container>
        </Flex>
      );
    }
    return <div />;
  }
}

function mapStateToProps(state) {
  return {
    results: state.form.results,
    attachments: state.form.attachments,
    step: state.form.currentStep,
    disabled: state.form.disabled,
    requiredFieldKeys: state.form.requiredFieldKeys,
    shouldIncludeCoverLetter: state.form.shouldIncludeCoverLetter,
    DrugOptionId: state.form.DrugOptionId,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    syncLocalState: ({ config, status, attachments, DrugOptionId }) => {
      dispatch(
        syncAuthorizationDetails({ config, status, attachments, DrugOptionId })
      );
    },
    goToLocation: (location) => {
      dispatch(push(location));
    },
    setToForm: (toSet) => dispatch(setFormFields(toSet)),
    setStep(step) {
      dispatch(setCurrentStep(step));
    },
    reset() {
      dispatch(resetForm());
    },
    toggleHighlightRequiredFields(shouldBeHighlighted) {
      dispatch(toggleHighlightRequiredFields(shouldBeHighlighted));
    },
  };
}

export const withAuthorization = graphql(withAuthorizationQuery, {
  options: (props) => {
    const params = new URLSearchParams(props.location.search);
    return {
      variables: { id: _.toNumber(params.get("id")) },
      fetchPolicy: "network-only",
    };
  },
  props: ({ data }) => ({
    authorization: data.authorizationById,
    authorizationRefetch: _.get(data, "refetch"),
  }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withUpdateFormDetails,
    withSubmitAuthorization,
    withUpdateAuthorizationProgress,
    withAuthorization,
    withFormById, // withFormById must be after withAuthorization
    withUpdateAuthorization,
    withCurrentAccount,
    withFeatureFlag
  )(withAlert()(NewAuthorizationHoc(Form)))
);
