import _ from "lodash";
import moment from "moment";
import { useState, useEffect } from "react";
import { useAlert } from "react-alert";

import {
  LoadingButton,
  Box,
  Drawer,
  Stack,
  Typography,
  Tab,
  TabPanel,
  TabContext,
  TabList,
} from "@samacare/design/core";
import { useTheme } from "@samacare/design/core/styles";
import CloseIcon from "@samacare/design/core/icons/Close";
import { usePatientTreatmentsContext } from "./PatientTreatmentsProvider";
import { PatientTreatmentCard } from "./PatientTreatmentCard";
import { InsuranceCoverageCheckWithAlert } from "@@components/InsuranceCoverageCheck";
import { formatPhoneNumber } from "../../components/PDFEditor/InputTypes/PhoneInput";
import { useRunEligibilityCheck } from "../../hooks/useRunEligibilityCheck";
import { usePatient } from "@@hooks/usePatient";
import CircularProgress from "@samacare/design/core/CircularProgress";
import { segmentTypewriter } from "../../../analytics/analytics";
import { InitiateBvLiteSource } from "../../../analytics/generated/segment";
import { useAccount } from "../../providers/AccountProvider";
import { getSegmentEnumInsuranceTypeFromSamacareInsuranceType } from "../../../analytics/util";

export const PatientTreatmentDetailPage: React.FC = () => {
  const theme = useTheme();
  const { open, toggleDrawer, activeRow } = usePatientTreatmentsContext();
  const alert = useAlert();
  const [activeTab, setActiveTab] = useState<string>("1");
  const [refreshBenefitsCheckLite] = useRunEligibilityCheck();
  const [refreshingEligibilityCheck, setRefreshingEligibilityCheck] =
    useState(false);
  const [eligibilityCheckError, setEligibilityCheckError] = useState(false);
  const [submitErrors, setSubmitErrors] = useState<string[]>([]);
  const patientResult = usePatient(Number(activeRow?.id));
  const account = useAccount();

  useEffect(() => {
    setSubmitErrors([]);
    setEligibilityCheckError(false);
  }, [activeRow]);

  const runEligibilityCheck = async () => {
    const { patient } = patientResult;
    try {
      setRefreshingEligibilityCheck(true);
      segmentTypewriter.initiateBvLite({
        accountId: +account!.id,
        email: account!.email!,
        isAdmin: account!.isAdmin,
        institutionIsTop: account!.institution!.isTop,
        institutionName: account!.institution!.name,
        institutionSpecialty:
          account!.institution!.practicingSpecialty ?? undefined,
        insuranceName: patient?.primaryInsurance?.insuranceCompany?.name ?? "",
        insuranceState: patient?.primaryInsurance?.insuranceState ?? undefined,
        insuranceType: getSegmentEnumInsuranceTypeFromSamacareInsuranceType(
          patient?.primaryInsurance?.planType ?? undefined
        ),
        initiateBvLiteSource: InitiateBvLiteSource.Treatment,
        //Patient treatment page does not have drug and icd info
        DrugOptionId: "",
        icdCodes: [],
      });
      const bvLiteResponse = await refreshBenefitsCheckLite({
        variables: { PatientId: activeRow?.id },
        refetchQueries: ["getPatientById", "getTreatmentsByPatient"],
        awaitRefetchQueries: true,
      });

      if (
        bvLiteResponse.data?.refreshPrimaryInsuranceCoverageCheck.success ===
        false
      ) {
        setEligibilityCheckError(true);
        setSubmitErrors(
          (
            bvLiteResponse.data?.refreshPrimaryInsuranceCoverageCheck
              .errorMessage ?? ""
          ).split("\n")
        );
      } else {
        alert.success("Eligibility check updated!");
      }
    } catch (e) {
      const error = e as Error;
      alert.error("Unable to run eligibility check");
      setEligibilityCheckError(true);
      setSubmitErrors(error.message.split("\n"));
    } finally {
      setRefreshingEligibilityCheck(false);
    }
  };

  if (patientResult.loading) {
    return <CircularProgress />;
  }

  return (
    <Drawer
      open={open && !!activeRow}
      sx={{ zIndex: 5 }}
      anchor="right"
      onClose={() => {
        if (toggleDrawer) toggleDrawer(false);
      }}
    >
      <Stack direction="column" padding={4} spacing={2} width={750}>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h6" color={theme.palette.text.primary}>
            {_.compact([
              activeRow?.firstName,
              activeRow?.lastName,
              `(${moment(activeRow?.dob).format("MM/DD/YYYY")})`,
            ]).join("  ")}
          </Typography>
          <LoadingButton
            type="button"
            size="small"
            variant="text"
            onClick={() => {
              if (toggleDrawer) toggleDrawer(false);
            }}
            sx={{ color: theme.palette.grey.A400 }}
          >
            <CloseIcon />
          </LoadingButton>
        </Stack>

        <Stack direction="column" spacing={0.5}>
          <Typography variant="body2" color={theme.palette.text.primary}>
            {activeRow?.address}
          </Typography>
          <Typography variant="body2" color={theme.palette.text.primary}>
            {_.compact([
              activeRow?.city,
              activeRow?.state,
              activeRow?.zip,
            ]).join(", ")}
          </Typography>
        </Stack>
        <Typography variant="body2" color={theme.palette.text.primary}>
          {activeRow?.phone ? formatPhoneNumber(activeRow?.phone) : "-"}
        </Typography>
        <Stack direction="row" spacing={4}>
          <Stack direction="column" spacing={1}>
            <Typography variant="subtitle2" color={theme.palette.text.primary}>
              MRN#
            </Typography>
            <Typography variant="body2" color={theme.palette.text.primary}>
              {activeRow?.institutionPatientId ?? "-"}
            </Typography>
          </Stack>
          <Stack direction="column" spacing={1}>
            <Typography variant="subtitle2" color={theme.palette.text.primary}>
              Primary Payor
            </Typography>
            <Typography variant="body2" color={theme.palette.text.primary}>
              {activeRow?.primaryInsurance?.insuranceCompany?.name ?? "-"}
            </Typography>
          </Stack>
          <Stack direction="column" spacing={1}>
            <Typography variant="subtitle2" color={theme.palette.text.primary}>
              Insurance State
            </Typography>
            <Typography variant="body2" color={theme.palette.text.primary}>
              {activeRow?.primaryInsurance?.insuranceState ?? "-"}
            </Typography>
          </Stack>
        </Stack>
        <InsuranceCoverageCheckWithAlert
          patient={patientResult.patient}
          isLoading={refreshingEligibilityCheck}
          onRunClick={runEligibilityCheck}
          eligibilityCheckError={eligibilityCheckError}
          submitErrors={submitErrors}
          displayAsRow={false}
        />

        <TabContext value={activeTab}>
          <Box>
            <TabList
              sx={{
                borderBottom: 1,
                borderColor: "divider",
              }}
              onChange={(_event, value: string) => setActiveTab(value)}
            >
              <Tab label="Treatments" value="1" />
            </TabList>
          </Box>
          <Box>
            <TabPanel value="1" sx={{ padding: 0 }}>
              <PatientTreatmentCard />
            </TabPanel>
          </Box>
        </TabContext>
      </Stack>
    </Drawer>
  );
};
