import { PureComponent } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withAlert } from "react-alert";
import { withApollo } from "@apollo/client/react/hoc";
import { compose } from "recompose";
import _ from "lodash";
import { PatientSection } from "AuthorizationSharedComponents/Patient";
import Section from "AuthorizationSharedComponents/Section";
import FormSubmitButtons from "AuthorizationSharedComponents/FormSubmitButtons";
import SegmentSelector from "Segment/SegmentSelector";
import PrimaryDrug from "AuthorizationSharedComponents/PrimaryDrug";
import { displayLetterOfMedicaNecessityForAuth } from "AuthorizationSharedSteps/LomnSection/LomnShared";
import { PrimaryNdc } from "AuthorizationSharedComponents/PrimaryNdc";
import { PrimaryButton } from "@@ui-kit";
import colors from "Resources/colors";
import { SegmentNote } from "../../../components/Segment/SegmentNote";

import { requireAuthEnrollmentRequiredFields } from "../../../util/enrollUtils";
import { withCurrentAccount } from "../../../graphql/Account";
import { withInsuranceCompanies } from "../../../graphql/InsuranceCompany";
import {
  InsuranceCompanySupportedCodingTypes,
  FilterFormBenefitType,
} from "@samacare/graphql";

import {
  withCreatePatientOnAuthorization,
  withUpdatePatient,
  findDuplicateAuthorizationsForPatientQuery,
} from "../../../graphql/Patient";
import { withRefreshPrimaryInsuranceCoverageCheck } from "../../../graphql/InsuranceCoverage";
import { setFormFields } from "../../../reducers/formReducer";
import RequiredTag from "../../../components/RequiredTag";
import BaseButton from "../../../components/BaseButton";
import AuthorizationModal from "../../../components/AuthorizationModal";
import MissingInformationModal from "../MissingInformationModalContent";
import Modal from "../../../components/Modal";
import { InsuranceCoverageCheck } from "../../../components/InsuranceCoverageCheck";
import filterInsuranceCompanyOptions from "../../../util/filterInsuranceCompanyOptions";
import { filterInsuranceCompaniesByDrugType } from "../../../util/filterInsuranceCompaniesByDrugType";
import { SponsoredBvCreationSection } from "../../../BenefitsVerifications/SponsoredBvCreationSection";
import InsuranceState from "./InsuranceState";
import InsurancePlanType from "./InsurancePlanType";
import { segmentTypewriter } from "../../../../analytics/analytics";
import { InitiateBvLiteSource } from "../../../../analytics/generated/segment";
import { getSegmentEnumInsuranceTypeFromSamacareInsuranceType } from "../../../../analytics/util";

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TextButton = styled.div`
  padding: 5px 10px;
  margin: 0 0 3px 10px;
  text-decoration: underline;
  color: ${({ theme }) => theme.purple};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.darkPurple};
    font-weight: 700;
  }
`;
const SingleLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const ButtonWrapper = styled.div`
  width: fit-content;
  height: 80%;
  justify-content: start;
  display: flex;
  flex-direction: row;
  margin: 10px 10px;
  border: ${(props) => (props.highlight ? `2px solid ${props.theme.red}` : "")};
`;

const getPlansFromParent = (companies, selectedCompanyName) => {
  const selectedCompany = _.find(companies, { name: selectedCompanyName });
  return selectedCompany
    ? _.filter(companies, {
        planParentCompanyId: selectedCompany.id,
      })
    : [];
};

const {
  AUTHORIZATION_STATUSES,
  MISSING_INFORMATION_TYPES,
  FORM_TYPES,
  TERMINAL_AUTHORIZATION_STATUSES,
} = CONFIG.CONSTANTS;
const fields = CONFIG.DEFAULT_FIELDS;

const getDefaultValueForSelect = (options) => {
  return options?.length === 1 ? options[0] : null;
};

export class InitializeSection extends PureComponent {
  state = {
    showMissingInfoModal: false,
    foundDuplicateAuthorizations: [],
    showFormToggleModal: false,
    refreshingEligibilityCheck: false,
    eligibilityCheckError: false,
    savingChanges: false,
    submitErrors: [],
  };

  companySelect = null;

  checkForDuplicateAuthorizations = () => {
    const { authorization, client, toggleFormModal, nextStep } = this.props;

    client
      .query({
        query: findDuplicateAuthorizationsForPatientQuery,
        variables: { id: authorization.id },
        fetchPolicy: "no-cache",
      })
      .then(({ data }) => {
        if (!_.isEmpty(data.findDuplicateAuthorizationsForPatient)) {
          this.setState({
            foundDuplicateAuthorizations:
              data.findDuplicateAuthorizationsForPatient,
          });
        } else if (toggleFormModal) {
          toggleFormModal();
        } else {
          nextStep();
        }
      });
  };

  saveChanges = async (patientId = null) => {
    const { alert } = this.props;
    this.setState({ savingChanges: true });
    try {
      await this.props.saveChanges(patientId);
    } catch (e) {
      alert.error("Unable to save changes");
    } finally {
      this.setState({ savingChanges: false });
    }
  };

  submit = async ({ redirect = true } = {}) => {
    const {
      insuranceCompanies,
      authorization,
      isInvalid,
      results,
      set,
      createPatientOnAuthorization,
      dob,
      firstName,
      lastName,
      account,
    } = this.props;

    let requiredFieldKeys = [
      fields.INSURANCE_STATE.key,
      fields.INSURANCE_COMPANY.key,
      ...(account.institution.featureFlags.pharmacyAndMedicalBenefitFormsEnabled
        ? [fields.REQUEST_TYPE.key]
        : []),
    ];
    if (
      getPlansFromParent(
        insuranceCompanies,
        results[fields.INSURANCE_COMPANY.key]
      ).length > 0
    ) {
      requiredFieldKeys = requiredFieldKeys.concat(fields.INSURANCE_PLAN.key);
    }

    set({ [fields.ALL_HCPCS.key]: results[fields.HCPCS_0.key] });

    if (
      isInvalid(
        _.some(requiredFieldKeys, (key) => !results[key]) ||
          !results[fields.PRIMARY_DRUG_NAME.key]
      )
    ) {
      return;
    }

    if (
      !results[fields.REQUEST_DESCRIPTION.key] &&
      results[fields.PRIMARY_DRUG_NAME.key]
    ) {
      set({
        [fields.REQUEST_DESCRIPTION.key]:
          results[fields.PRIMARY_DRUG_NAME.key].toUpperCase(),
      });
    }

    let createdPatientId = null;
    if (!authorization.patient) {
      const res = await createPatientOnAuthorization({
        variables: {
          authorizationId: authorization.id,
          patientProps: {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            dob,
          },
        },
      });
      createdPatientId = res.data.createPatientOnAuthorization.patient.id;
    }

    if (!redirect) {
      await this.saveChanges(createdPatientId);
      return createdPatientId;
    }

    if (
      (authorization.formId && authorization.submissionPhoneNumber) ||
      authorization.status === AUTHORIZATION_STATUSES.PREMIUM_RX_SUPPORT
    ) {
      await this.success({ redirect, createdPatientId });
    } else {
      await this.saveChanges(createdPatientId);
      await this.checkForDuplicateAuthorizations();
    }

    return createdPatientId;
  };

  success = ({ redirect = true, createdPatientId }) => {
    const {
      nextStep,
      authorization,
      dob,
      firstName,
      lastName,
      createPatientOnAuthorization,
      toggleFormModal,
    } = this.props;
    const { showFormToggleModal } = this.state;

    if (!authorization.patient) {
      return createPatientOnAuthorization({
        variables: {
          authorizationId: authorization.id,
          patientProps: {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            dob,
          },
        },
      }).then((res) =>
        redirect
          ? nextStep(res)
          : res.data.createPatientOnAuthorization.patient.id
      );
    }

    if (showFormToggleModal) {
      this.setState({ showFormToggleModal: !showFormToggleModal });
      if (toggleFormModal) {
        toggleFormModal();
      } else {
        nextStep();
      }
    }

    return redirect ? nextStep() : createdPatientId;
  };

  showError = (message, causeError) => {
    const { alert } = this.props;
    alert.error(message);
    // eslint-disable-next-line no-console
    console.error(message, causeError);
  };

  runEligibilityCheck = async () => {
    const {
      alert,
      authorization: { patient, DrugOptionId, ICDs },
      refreshPrimaryInsuranceCoverageCheck,
      account,
    } = this.props;

    // Submit form if patient has not been created / associated to the PA
    // `redirect=false` prevents the submission to advance flow step
    let PatientId = patient?.id;
    if (PatientId == null) {
      PatientId = await this.submit({ redirect: false });
    }

    // After PA form submission completes, backend has all data required for eligibility check
    try {
      segmentTypewriter.initiateBvLite({
        accountId: +account.id,
        email: account.email,
        isAdmin: account.isAdmin,
        institutionIsTop: account.institution.isTop,
        institutionName: account.institution.name,
        institutionSpecialty:
          account.institution.practicingSpecialty ?? undefined,
        insuranceName:
          patient.primaryInsurance?.insuranceCompany.name ?? undefined,
        insuranceState: patient.primaryInsurance?.insuranceState ?? undefined,
        insuranceType: getSegmentEnumInsuranceTypeFromSamacareInsuranceType(
          patient.primaryInsurance?.planType ?? undefined
        ),
        DrugOptionId: DrugOptionId ?? "",
        icdCodes: ICDs,
        initiateBvLiteSource: InitiateBvLiteSource.Pa,
      });

      this.setState({ refreshingEligibilityCheck: true });
      const { data } = await refreshPrimaryInsuranceCoverageCheck({
        variables: { PatientId },
      });

      if (data.refreshPrimaryInsuranceCoverageCheck.success === false) {
        this.showError(
          "Unable to run eligibility check",
          data.refreshPrimaryInsuranceCoverageCheck.errorMessage ?? ""
        );
        this.setState({
          eligibilityCheckError: true,
          submitErrors:
            data.refreshPrimaryInsuranceCoverageCheck.errorMessage.split("\n"),
        });
      } else {
        alert.success("Eligibility check updated!");
      }
    } catch (e) {
      this.showError("Unable to run eligibility check", e);
      this.setState({
        eligibilityCheckError: true,
        submitErrors: e.message.split("\n"),
      });
    } finally {
      this.setState({ refreshingEligibilityCheck: false });
    }
  };

  onMemberIdChange = _.debounce(
    async (e) => {
      const { set } = this.props;
      await set({
        [fields.PATIENT_MEMBER_ID.key]: e.target.value,
      });
      await this.saveChanges();
      this.setState({ eligibilityCheckError: false, submitErrors: [] });
    },
    1000,
    { leading: false, trailing: true }
  );

  render() {
    const {
      authorization,
      insuranceCompanies,
      lastName,
      firstName,
      dob,
      institutionPatientId,
      disabled,
      set,
      state,
      planType,
      results,
      account,
      toggleFormModal,
      highlightRequiredFields,
      nextStep,
    } = this.props;
    const {
      showMissingInfoModal,
      foundDuplicateAuthorizations,
      refreshingEligibilityCheck,
      eligibilityCheckError,
      submitErrors,
    } = this.state;
    const selectedCompany = _.find(insuranceCompanies, {
      name: results[fields.INSURANCE_COMPANY.key],
    });

    if (!account) {
      return <div />;
    }

    // (2024-02-22) Leo: I couldn't figure out a better way to trigger a save when member ID changes
    // Updating the member ID in the DB is important, because the health coverage check relies on udpated insurance info in DB

    const onInsuranceTypeChange = async (value) => {
      if (results[fields.INSURANCE_TYPE.key] === value) return;

      await set({
        [fields.INSURANCE_TYPE.key]: value,
      });
      await this.saveChanges();
      this.setState({ eligibilityCheckError: false, submitErrors: [] });
    };

    const onInsuranceStateChange = async (value) => {
      if (results[fields.INSURANCE_STATE.key] === value) return;

      await set({
        [fields.INSURANCE_STATE.key]: value || null,
      });
      await this.saveChanges();
      this.setState({ eligibilityCheckError: false, submitErrors: [] });
    };

    const memberIdFieldWithAutoSave = {
      ...fields.PATIENT_MEMBER_ID,
      onChange: this.onMemberIdChange,
    };

    let insuranceSection = [memberIdFieldWithAutoSave];

    if (
      account.institution.practicingSpecialty ===
      CONFIG.CONSTANTS.PRACTICING_SPECIALTY_TYPES.ONCOLOGY
    ) {
      insuranceSection = [fields.PATIENT_GROUP_NUMBER, ...insuranceSection];
      // only need this for Novartis data collection right now
    }

    let insurancePlans = getPlansFromParent(
      insuranceCompanies,
      results[fields.INSURANCE_COMPANY.key]
    );
    if (insurancePlans.length > 0) {
      insurancePlans = _.concat(insurancePlans, selectedCompany);
    }
    const selectedPlan = _.find(insurancePlans, {
      name: results[fields.INSURANCE_PLAN.key],
    });

    const requireCareMetxEnrollmentFields = requireAuthEnrollmentRequiredFields(
      authorization,
      account
    );

    return (
      <FormContainer id="pendo_formAuth_initializeSection">
        <PatientSection
          authorizationId={authorization.id}
          firstName={firstName}
          lastName={lastName}
          dob={dob}
          institutionPatientId={institutionPatientId}
          showSearchByMRN={account.institution.integrations.length > 0}
          patientId={_.get(authorization, "patient.id")}
          currentStep={authorization.formDetails.currentStep}
        />
        <Section
          section={{
            items: [
              {
                ...fields.PATIENT_ADDRESS_LINE,
                required: requireCareMetxEnrollmentFields,
              },
              [
                {
                  ...fields.PATIENT_CITY,
                  required: requireCareMetxEnrollmentFields,
                },
                {
                  ...fields.PATIENT_STATE,
                  required: requireCareMetxEnrollmentFields,
                },
                {
                  ...fields.PATIENT_ZIP,
                  required: requireCareMetxEnrollmentFields,
                },
              ],
              ..._.compact([
                requireCareMetxEnrollmentFields && {
                  ...fields.PATIENT_PHONE,
                  required: true,
                },
                (requireCareMetxEnrollmentFields ||
                  displayLetterOfMedicaNecessityForAuth(authorization)) && {
                  type: FORM_TYPES.OPTION,
                  title: "Gender",
                  options: [
                    fields.PATIENT_GENDER_FEMALE,
                    fields.PATIENT_GENDER_MALE,
                  ],
                  required: true,
                },
              ]),
            ],
            title: "Patient Information",
            help: "This information is sometimes optional, but may be required on forms",
          }}
        />
        {!account.institution.featureFlags
          .pharmacyAndMedicalBenefitFormsEnabled && (
          <PrimaryDrug
            authorization={authorization}
            saveChanges={this.saveChanges}
          />
        )}
        {account.institution.featureFlags
          .pharmacyAndMedicalBenefitFormsEnabled && (
          <Section
            showRequiredTag
            section={{
              title: "Primary service",
            }}
          >
            <ButtonWrapper
              highlight={
                highlightRequiredFields && !results[fields.REQUEST_TYPE.key]
              }
            >
              <PrimaryButton
                data-cy="controlMedicalRequest"
                disabled={
                  results[fields.REQUEST_TYPE.key] ===
                  CONFIG.CONSTANTS.REQUEST_TYPE.MEDICAL_REQUEST
                }
                inverted
                disabledBackgroundColor="white"
                disabledColor={colors.primary}
                style={{ marginRight: "10px" }}
                onClick={() => {
                  set({
                    [fields.DRUG_CODE_TYPE.key]:
                      InsuranceCompanySupportedCodingTypes.Jcode,
                    [fields.BENEFIT_TYPES.key]:
                      FilterFormBenefitType.MedicalOnly,
                    [fields.REQUEST_TYPE.key]:
                      CONFIG.CONSTANTS.REQUEST_TYPE.MEDICAL_REQUEST,
                    [fields.PRIMARY_NDC.key]: null,
                    [fields.PRIMARY_DRUG_NAME.key]: null,
                    [fields.REQUEST_DESCRIPTION.key]: null,
                  });
                }}
              >
                Medical Request
              </PrimaryButton>
              <PrimaryButton
                data-cy="controlSpecialtyPharmacyRequest"
                disabled={
                  results[fields.REQUEST_TYPE.key] ===
                  CONFIG.CONSTANTS.REQUEST_TYPE.SPECIALTY_PHARMACY_REQUEST
                }
                inverted
                disabledBackgroundColor="white"
                disabledColor={colors.primary}
                style={{ marginRight: "10px" }}
                onClick={() => {
                  set({
                    [fields.DRUG_CODE_TYPE.key]:
                      InsuranceCompanySupportedCodingTypes.Jcode,
                    [fields.BENEFIT_TYPES.key]:
                      FilterFormBenefitType.MedicalOnly,
                    [fields.REQUEST_TYPE.key]:
                      CONFIG.CONSTANTS.REQUEST_TYPE.SPECIALTY_PHARMACY_REQUEST,
                    [fields.PRIMARY_NDC.key]: null,
                    [fields.PRIMARY_DRUG_NAME.key]: null,
                    [fields.REQUEST_DESCRIPTION.key]: null,
                  });
                }}
              >
                Specialty Pharmacy Request
              </PrimaryButton>
              <PrimaryButton
                data-cy="controlPharmacyRequest"
                disabled={
                  results[fields.REQUEST_TYPE.key] ===
                  CONFIG.CONSTANTS.REQUEST_TYPE.PHARMACY_REQUEST
                }
                inverted
                disabledBackgroundColor="white"
                disabledColor={colors.primary}
                style={{ marginRight: "10px" }}
                onClick={() => {
                  set({
                    [fields.DRUG_CODE_TYPE.key]:
                      InsuranceCompanySupportedCodingTypes.Ndc,
                    [fields.BENEFIT_TYPES.key]:
                      FilterFormBenefitType.PharmacyOnly,
                    [fields.REQUEST_TYPE.key]:
                      CONFIG.CONSTANTS.REQUEST_TYPE.PHARMACY_REQUEST,
                    [fields.HCPCS_0.key]: null,
                    [fields.ALL_HCPCS.key]: null,
                    [fields.PRIMARY_DRUG_NAME.key]: null,
                    [fields.REQUEST_DESCRIPTION.key]: null,
                  });
                }}
              >
                Pharmacy Request
              </PrimaryButton>
            </ButtonWrapper>
            {results[fields.DRUG_CODE_TYPE.key] ===
              InsuranceCompanySupportedCodingTypes.Jcode && (
              <PrimaryDrug
                authorization={authorization}
                saveChanges={this.saveChanges}
                highlight={highlightRequiredFields}
              />
            )}
            {results[fields.DRUG_CODE_TYPE.key] ===
              InsuranceCompanySupportedCodingTypes.Ndc && (
              <PrimaryNdc results={results} set={set} />
            )}
          </Section>
        )}
        {authorization.patient && (
          <SponsoredBvCreationSection auth={authorization} />
        )}
        <Section
          childrenAfterSection
          section={{
            items: insuranceSection,
            title: "Patient Insurance Information",
          }}
        >
          <SingleLine>
            <SegmentSelector
              id="fieldInsuranceOrganization"
              disabled={disabled}
              highlight={highlightRequiredFields}
              onChange={async (company) => {
                const changes = {
                  [fields.INSURANCE_STATE.key]: getDefaultValueForSelect(
                    company?.supportedStates
                  ),
                  [fields.INSURANCE_TYPE.key]: getDefaultValueForSelect(
                    company?.supportedPlanTypes
                  ),
                };

                if (company) {
                  changes[fields.INSURANCE_COMPANY.key] = company.name;
                  changes[fields.INSURANCE_PLAN.key] = null;
                } else {
                  changes[fields.INSURANCE_COMPANY.key] = null;
                }

                await set(changes);
                this.setState({
                  showFormToggleModal: true,
                  eligibilityCheckError: false,
                  submitErrors: [],
                });
                await this.saveChanges();
              }}
              options={
                insuranceCompanies &&
                _.sortBy(
                  filterInsuranceCompaniesByDrugType(
                    insuranceCompanies,
                    results[fields.DRUG_CODE_TYPE.key]
                  ),
                  "name"
                )
              }
              setRef={(ref) => (this.companySelect = ref)}
              title={
                <TitleContainer>
                  Insurance Organization
                  <RequiredTag />
                </TitleContainer>
              }
              value={selectedCompany}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
              sorted
              filterOption={(option, value) =>
                filterInsuranceCompanyOptions(option.data, value)
              }
            />
            <TextButton
              onClick={() => {
                this.setState({ showMissingInfoModal: true });
              }}
            >
              + Additional payers
            </TextButton>
          </SingleLine>
          {selectedCompany && (
            <>
              <SingleLine>
                <InsuranceState
                  id="fieldInsuranceState"
                  title={
                    <TitleContainer>
                      Issuing State
                      <RequiredTag />
                    </TitleContainer>
                  }
                  help="This is the state that the insurance is associated with, not the location of the practice (refer to insurance card)"
                  onChange={onInsuranceStateChange}
                  highlightRequiredFields={highlightRequiredFields}
                  value={state}
                  supportedStates={selectedCompany?.supportedStates}
                />
              </SingleLine>
              <SingleLine>
                <InsurancePlanType
                  id="fieldInsuranceType"
                  title={
                    <TitleContainer>
                      {fields.INSURANCE_TYPE.title}
                      <RequiredTag />
                    </TitleContainer>
                  }
                  help={fields.INSURANCE_TYPE.help}
                  onChange={onInsuranceTypeChange}
                  highlightRequiredFields={highlightRequiredFields}
                  value={planType}
                  supportedPlanTypes={selectedCompany?.supportedPlanTypes}
                />
              </SingleLine>
            </>
          )}
          <SegmentNote field="insuranceOrganization" />
          {insurancePlans.length > 0 && (
            <SingleLine>
              <SegmentSelector
                onChange={(company) => {
                  if (company) {
                    set({ [fields.INSURANCE_PLAN.key]: company.name });
                  } else {
                    set({ [fields.INSURANCE_PLAN.key]: null });
                  }
                  this.setState({ showFormToggleModal: true });
                }}
                options={_.sortBy(insurancePlans, "name")}
                title={
                  <TitleContainer>
                    Insurance Plan
                    <RequiredTag />
                  </TitleContainer>
                }
                value={selectedPlan}
                disabled={disabled}
                sorted
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                filterOption={filterInsuranceCompanyOptions}
              />
            </SingleLine>
          )}
          <InsuranceCoverageCheck
            patient={authorization.patient}
            selectedInsuranceCompany={selectedCompany}
            isLoading={refreshingEligibilityCheck}
            onRunClick={this.runEligibilityCheck}
            disabled={
              this.state.savingChanges ||
              TERMINAL_AUTHORIZATION_STATUSES.includes(authorization.status)
            }
            eligibilityCheckError={eligibilityCheckError}
            submitErrors={submitErrors}
          />
        </Section>
        <FormSubmitButtons submit={this.submit} />
        {!_.isEmpty(foundDuplicateAuthorizations) && (
          <AuthorizationModal
            open
            authorizations={foundDuplicateAuthorizations}
            iteratePage={() => {}}
            headerText="Possible duplicate authorizations found"
            bodyText="Someone in your office recently submitted authorization(s) for this patient. To save time and effort, check
to ensure you're not duplicating an existing authorization."
            customButtons={[
              <BaseButton
                key="duplicateGoBack"
                onClick={() => {
                  this.setState({ foundDuplicateAuthorizations: [] });
                }}
              >
                Go Back
              </BaseButton>,
              <BaseButton
                style={{ marginLeft: 10 }}
                key="duplicateContinue"
                onClick={() => {
                  if (toggleFormModal) {
                    toggleFormModal();
                  } else {
                    nextStep();
                  }
                  this.setState({ foundDuplicateAuthorizations: [] });
                }}
              >
                Continue with Authorization
              </BaseButton>,
            ]}
          />
        )}
        {showMissingInfoModal && (
          <Modal
            onClick={() => {
              this.setState({ showMissingInfoModal: false });
            }}
            open={showMissingInfoModal}
            styleOverride={{ padding: "20px" }}
          >
            <MissingInformationModal
              type={MISSING_INFORMATION_TYPES.INSURANCE_COMPANY}
              authorizationId={authorization.id}
              onCancel={() => {
                this.setState({ showMissingInfoModal: false });
              }}
              insuranceCompanyId={_.get(selectedCompany, "id")}
              state={state}
            />
          </Modal>
        )}
      </FormContainer>
    );
  }
}

function mapStateToProps(state) {
  return {
    firstName: state.form.results[fields.PATIENT_FIRST_NAME.key],
    lastName: state.form.results[fields.PATIENT_LAST_NAME.key],
    dob: state.form.results[fields.PATIENT_DOB.key],
    institutionPatientId: state.form.results[fields.INSTITUTION_PATIENT_ID.key],
    state: state.form.results[fields.INSURANCE_STATE.key],
    planType: state.form.results[fields.INSURANCE_TYPE.key],
    highlightRequiredFields: state.form.highlightRequiredFields,
    disabled: state.form.disabled,
    results: state.form.results,
  };
}

const mapDispatchToProps = (dispatch) => ({
  set(toSet) {
    dispatch(setFormFields(toSet));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  compose(
    withCreatePatientOnAuthorization,
    withUpdatePatient,
    withInsuranceCompanies,
    withCurrentAccount,
    withRefreshPrimaryInsuranceCoverageCheck
  )(withApollo(withAlert()(InitializeSection)))
);
